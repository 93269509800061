<template>
  <div class="row">
    <div class="col-12 col-md-3 col-xl-2 mb-4">
      <TheNavigation />
    </div>
    <div class="col-12 col-md-9 col-xl-10 mb-4">
      <div v-if="$store.getters.isOld" class="mb-3">
        <div class="alert alert-primary">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <i class="fad fa-sparkles text-primary"></i>
            </div>
            <div>
              Activate new features by switching to our new subscription plans. <router-link :to="{ name: 'subscription' }">Proceed <i class="fal fa-long-arrow-right"></i></router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!$store.state.user.active" class="mb-3">
        <div class="alert alert-danger">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <i class="fad fa-do-not-enter"></i>
            </div>
            <div>
              Your account has been suspended. Please contact our team if this is mistaken.
            </div>
          </div>
        </div>
      </div>
      <div v-if="!$store.state.user.verified" class="mb-3">
        <div class="alert alert-warning">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <i class="fad fa-exclamation-triangle"></i>
            </div>
            <div>
              Your account is not verified. Please visit the notification settings to confirm your email address.
            </div>
          </div>
        </div>
      </div>
      <transition name="view" mode="out-in" appear>
        <router-view :key="$route.fullPath"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import TheNavigation from '@/components/TheNavigation.vue'

export default {
  components: {
    TheNavigation,
  },
}
</script>
