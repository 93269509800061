<template>
  <div id="app" class="container">
    <header class="pt-3 mb-4">
      <TheHeader />
    </header>
    <main class="mb-4">
      <div v-if="isOldIE" class="alert alert-warning mb-4">
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <i class="fad fa-exclamation-triangle"></i>
          </div>
          <div>
            You’re on an outdated browser. Please switch to a modern browser to use this dashboard.
          </div>
        </div>
      </div>
      <transition name="view" mode="out-in" appear>
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'

function isOldIE() {
  const ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf('MSIE ') // IE 10 or older
  const trident = ua.indexOf('Trident/') //IE 11
  return (msie > 0 || trident > 0)
}

export default {
  components: {
    TheHeader,
  },

  computed: {
    isOldIE() {
      return isOldIE()
    },
  },
}
</script>
