import Vue from 'vue'
import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Switch,
  Tabs,
  Tooltip,
  Upload,
} from 'ant-design-vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/main.css'
import './assets/css/ant.css'

Vue.config.productionTip = false

Vue.prototype.$httpd = axios.create({
  baseURL: process.env.VUE_APP_API_DASHBOARD,
  timeout: 50000, // For CSV downloads.
  withCredentials: true,
})

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_API_PUBLIC,
  timeout: 10000,
})

Vue.use(Button)
Vue.use(Checkbox)
Vue.use(Collapse)
Vue.use(DatePicker)
Vue.use(Dropdown)
Vue.use(Form)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Modal)
Vue.use(Popconfirm)
Vue.use(Radio)
Vue.use(Select)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(Tooltip)
Vue.use(Upload)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$error = Modal.error

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
