import Vue from 'vue'
import Router from 'vue-router'
import TheDashboard from '@/components/TheDashboard'
import store from './store'

Vue.use(Router)

var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    component: TheDashboard,
    children: [{
      path: '/',
      name: 'index',
      meta: { title: 'Dashboard', requiresAuth: true },
      component: () => import(/* webpackChunkName: "index" */ '@/views/index.vue'),
    }, {
      path: '/batches',
      name: 'batches',
      meta: { title: 'Batches', requiresAuth: true },
      component: () => import(/* webpackChunkName: "batches" */ '@/views/batches/index.vue'),
    }, {
      path: '/batches/:id',
      name: 'batches-id',
      meta: { title: 'Batch Object', requiresAuth: true },
      component: () => import(/* webpackChunkName: "batches" */ '@/views/batches/_id.vue'),
    }, {
      path: '/delete',
      name: 'delete',
      meta: { title: 'Delete Account', requiresAuth: true },
      component: () => import(/* webpackChunkName: "delete" */ '@/views/delete.vue'),
    }, {
      path: '/events',
      name: 'events',
      meta: { title: 'Events', requiresAuth: true },
      component: () => import(/* webpackChunkName: "events" */ '@/views/events/index.vue'),
    }, {
      path: '/events/:id',
      name: 'events-id',
      meta: { title: 'Event Object', requiresAuth: true },
      component: () => import(/* webpackChunkName: "events" */ '@/views/events/_id.vue'),
    }, {
      path: '/integrations',
      name: 'integrations',
      meta: { title: 'Integrations', requiresAuth: true },
      component: () => import(/* webpackChunkName: "integrations" */ '@/views/integrations.vue'),
    }, {
      path: '/invoices',
      name: 'invoices',
      meta: { title: 'Invoices', requiresAuth: true },
      component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices.vue'),
    }, {
      path: '/keys',
      name: 'keys',
      meta: { title: 'Manage API Keys', requiresAuth: true },
      component: () => import(/* webpackChunkName: "keys" */ '@/views/keys.vue'),
    }, {
      path: '/notifications',
      name: 'notifications',
      meta: { title: 'Notifications', requiresAuth: true },
      component: () => import(/* webpackChunkName: "notifications" */ '@/views/notifications.vue'),
    }, {
      path: '/password',
      name: 'password',
      meta: { title: 'Password', requiresAuth: true },
      component: () => import(/* webpackChunkName: "password" */ '@/views/password.vue'),
    }, {
      path: '/quotes',
      name: 'quotes',
      meta: { title: 'Quotes', requiresAuth: true },
      component: () => import(/* webpackChunkName: "quotes" */ '@/views/quotes/index.vue'),
    }, {
      path: '/quotes/:id',
      name: 'quotes-id',
      meta: { title: 'Quote Object', requiresAuth: true },
      component: () => import(/* webpackChunkName: "quotes" */ '@/views/quotes/_id.vue'),
    }, {
      path: '/regions',
      name: 'regions',
      meta: { title: 'Tax Regions', requiresAuth: true },
      component: () => import(/* webpackChunkName: "regions" */ '@/views/regions/index.vue'),
    }, {
      path: '/regions/:id',
      name: 'regions-id',
      meta: { title: 'Tax Region Settings', requiresAuth: true },
      component: () => import(/* webpackChunkName: "regions" */ '@/views/regions/_id.vue'),
    }, {
      path: '/reports',
      name: 'reports',
      meta: { title: 'Reports', requiresAuth: true },
      component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/index.vue'),
    }, {
      path: '/reports/eu_vat',
      name: 'reports-eu_vat',
      meta: { title: 'VAT OSS (EU)', requiresAuth: true },
      component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/eu_vat.vue'),
    }, {
      path: '/reports/ec_sales_list',
      name: 'reports-ec_sales_list',
      meta: { title: 'EC Sales List', requiresAuth: true },
      component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/ec_sales_list.vue'),
    }, {
      path: '/reports/:type',
      name: 'reports-type',
      meta: { title: 'Report', requiresAuth: true },
      component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/_type.vue'),
    }, {
      path: '/subscription',
      name: 'subscription',
      meta: { title: 'Subscription', requiresAuth: true },
      component: () => import(/* webpackChunkName: "subscription" */ '@/views/subscription.vue'),
    }, {
      path: '/supplies',
      name: 'supplies',
      meta: { title: 'Supplies', requiresAuth: true },
      component: () => import(/* webpackChunkName: "supplies" */ '@/views/supplies/index.vue'),
    }, {
      path: '/supplies/:id',
      name: 'supplies-id',
      meta: { title: 'Supply Object', requiresAuth: true },
      component: () => import(/* webpackChunkName: "supplies" */ '@/views/supplies/_id.vue'),
    }, {
      path: '/validations',
      name: 'validations',
      meta: { title: 'Validations', requiresAuth: true },
      component: () => import(/* webpackChunkName: "validations" */ '@/views/validations/index.vue'),
    }, {
      path: '/validations/:id',
      name: 'validations-id',
      meta: { title: 'Validation Object', requiresAuth: true },
      component: () => import(/* webpackChunkName: "validations" */ '@/views/validations/_id.vue'),
    }, {
      path: '/endpoints',
      name: 'endpoints',
      meta: { title: 'Webhook Endpoints', requiresAuth: true },
      component: () => import(/* webpackChunkName: "endpoints" */ '@/views/endpoints/index.vue'),
    }, {
      path: '/endpoints/:id',
      name: 'endpoints-id',
      meta: { title: 'Webhook Endpoint', requiresAuth: true },
      component: () => import(/* webpackChunkName: "endpoints" */ '@/views/endpoints/_id.vue'),
    }],
  }, {
    path: '/login',
    name: 'login',
    meta: { title: 'Login' },
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
  }, {
    path: '/login/:id',
    name: 'login-id',
    meta: { title: 'Login' },
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/_id.vue'),
  }, {
    path: '/register',
    name: 'register',
    meta: { title: 'Create Account' },
    component: () => import(/* webpackChunkName: "register" */ '@/views/register.vue'),
  }, {
    path: '/reset',
    name: 'reset',
    meta: { title: 'Password Reset' },
    component: () => import(/* webpackChunkName: "reset" */ '@/views/reset/index.vue'),
  }, {
    path: '/reset/:id',
    name: 'reset-id',
    meta: { title: 'Password Reset' },
    component: () => import(/* webpackChunkName: "reset" */ '@/views/reset/_id.vue'),
  }, {
    path: '/404',
    alias: '*',
    name: '404',
    meta: { title: '404: Page Not Found' },
    component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
  }]
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = `${ to.meta.title } - Vatstack`
  } else {
    document.title = 'Vatstack'
  }

  if (!store.state.csrfToken) {
    await store.dispatch('refreshToken')
  }

  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.getters.isAuth) {
      return next()
    } else {
      try {
        if (await store.dispatch('refresh')) {
          return next()
        } else {
          return next({ name: 'login' })
        }
      } catch (err) {
        return next({ name: 'login' })
      }
    }
  }

  return next()
})

export default router
