<template>
  <div>
    <nav>
      <ul class="list-unstyled mb-4">
        <li>
          <router-link :to="{ name: 'index' }" exact>
            <i class="fad fa-fw fa-home mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Home
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'reports' }">
            <i class="fad fa-fw fa-file-chart-line mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Reports
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="mb-4">
      <div class="rounded-pill px-2 py-1" :class="{ 'bg-warning': !livemode, 'bg-white': livemode }">
        <a-switch
          v-model="livemode"
          default-checked
          size="small"
          class="mr-2"
        />
        <small><strong>{{ livemode ? 'Production' : 'Development' }}</strong></small>
      </div>
    </div>
    <nav class="mb-4">
      <ul class="list-unstyled">
        <li>
          <router-link :to="{ name: 'validations' }">
            <i class="fad fa-fw fa-badge-check mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Validations
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'batches' }">
            <i class="fad fa-fw fa-calendar-day mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Batches
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'quotes' }">
            <i class="fad fa-fw fa-calculator mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Quotes
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'supplies' }">
            <i class="fad fa-fw fa-cash-register mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Supplies
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'events' }">
            <i class="fad fa-fw fa-bolt mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Events
          </router-link>
        </li>
      </ul>
    </nav>
    <nav class="mb-4">
      <label class="label mb-2">Developers</label>
      <ul class="list-unstyled">
        <li>
          <router-link :to="{ name: 'endpoints' }">
            <i class="fad fa-fw fa-anchor mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Webhooks
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'keys' }">
            <i class="fad fa-fw fa-chart-bar mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> API Keys
          </router-link>
        </li>
      </ul>
    </nav>
    <nav class="mb-4">
      <label class="label mb-2">Account</label>
      <ul class="list-unstyled">
        <li>
          <router-link :to="{ name: 'regions' }">
            <i class="fad fa-fw fa-globe mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Tax Regions
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'notifications' }">
            <i class="fad fa-fw fa-envelope-open mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Notifications
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'subscription' }">
            <i class="fad fa-fw fa-credit-card-front mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Subscription
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'invoices' }">
            <i class="fad fa-fw fa-receipt mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Invoices
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'integrations' }">
            <i class="fad fa-fw fa-sync mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Integrations
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'password' }">
            <i class="fad fa-fw fa-key mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Password
          </router-link>
        </li>
      </ul>
    </nav>
    <div class="mb-4" v-if="!$store.getters.isSubscribed">
      <router-link :to="{ name: 'subscription' }">
        <i class="fad fa-fw fa-plus mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Start at $0
      </router-link>
    </div>
    <ul class="list-unstyled mb-4">
      <li>
        <a tabindex="0" style="cursor: pointer; outline: none;" @click="onLogout">
          <i class="fad fa-fw fa-power-off mr-2" style="--fa-primary-opacity: 0.70; --fa-secondary-opacity: 0.30;"></i> Logout
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    async onLogout() {
      try {
        await this.$httpd.delete('/session')
        await this.$store.dispatch('refreshToken')

        this.$router.push({ name: 'login' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }
    },
  },

  computed: {
    livemode: {
      get() {
        return this.$store.state.livemode
      },
      set(value) {
        this.$store.commit('livemode', value)
      },
    },
  },
}
</script>

<style scoped>
nav ul li {
  margin-bottom: .35rem;
}

nav a {
  color: #19152f;
  text-decoration: none;
}

nav a.router-link-active {
  font-weight: 500;
  color: #4e1fbf;
  text-decoration: none;
}
</style>
